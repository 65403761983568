<script>
import AdDetailsCard from "./AdDetailsCard.vue";
import ApplyToAllCard from "./ApplyToAllCard.vue";
import { MarktingService } from "../../../services";
export default {
  components: { AdDetailsCard, ApplyToAllCard },
  data() {
    return {
      adsList: [
        {
          key: "first",
          name: "",
          visible: true,
          sectionType: "section",
          providerId: undefined,
          destination: undefined,
          imageUrl: "",
          videoUrl: "",
          isSaved: false,
          mediaType: "image",
          type: "banner",
          regionsList: [],
          regions: [],
          areas: [],
          areasList: [],
          ad_config: {},
        },
      ],
      checkAll: false,
      selectedAds: [],
      isIndeterminate: false,
    };
  },
  computed: {
    showApplyToAllCard() {
      return this.selectedAds.filter((ad) => !ad?.isSaved).length > 0;
    },
  },
  beforeRouteLeave(to, from, next) {
    const unSavedAdsCount = this.adsList.filter((ad) => !ad?.isSaved).length;
    if (unSavedAdsCount) {
      const answer = window.confirm(
        "Do you really want to leave? you have unsaved Ads!"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    generateAds(mediaUrls, names, types) {
      mediaUrls.forEach((url, i) => {
        const type = types[i].split("/")[0];
        const adObject = {
          key: url,
          name: names[i],
          visible: true,
          sectionType: "section",
          providerId: undefined,
          destination: undefined,
          imageUrl: "",
          videoUrl: "",
          isSaved: false,
          regionsList: [],
          regions: [],
          areas: [],
          areasList: [],
          ad_config: {},
        };
        if (type == "image") {
          adObject["imageUrl"] = url;
          adObject["type"] = "banner";
          adObject["mediaType"] = "image";
        } else if (type == "video") {
          adObject["videoUrl"] = url;
          adObject["type"] = "popup";
          adObject["mediaType"] = "video";
          adObject["ad_config"]["is_playable"] = true;
        }
        this.adsList.push(adObject);
      });
    },
    async mediaEdited({ newUri, ad }) {
      const mediaType = newUri.type.split("/")[0];
      const fileName = newUri.name;
      if (mediaType != ad.mediaType) {
        this.$message({
          message: this.$t("CreateAdPageEditMeidaErr", [
            mediaType,
            ad.mediaType,
          ]),
          type: "error",
        });
        return;
      }
      const adIndex = this.adsList.findIndex(
        (a) => a[`${ad.mediaType}Url`] == ad[`${ad.mediaType}Url`]
      );
      let imageUploadFD = new FormData();
      imageUploadFD.append("image", newUri);
      try {
        const response = await MarktingService.ads.bulkUploadMedias(
          imageUploadFD
        );
        this.$message({
          message: this.$t("CreateAdPageEditMediaMsg"),
          type: this.$t("globalsSuccessTitle"),
        });
        this.adsList[adIndex][`${ad.mediaType}Url`] = response.data[0];
        this.adsList[adIndex].name = fileName;
      } catch (error) {
        console.log(error);
      }
    },

    async handleMediaUpload(e) {
      const files = e.target.files;
      // get file names and types to use it in generating the ads
      let fileNames = [];
      let fileTypes = [];
      let imageUploadFD = new FormData();
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        imageUploadFD.append("image", file);
        fileNames.push(file.name);
        fileTypes.push(file.type);
      }
      try {
        const response = await MarktingService.ads.bulkUploadMedias(
          imageUploadFD
        );
        // generate ads from media url
        this.generateAds(response.data, fileNames, fileTypes);
        this.$message({
          message: this.$t("CreateAdPageMediaUploadMsg"),
          type: this.$t("globalsSuccessTitle"),
        });
      } catch (error) {
        this.$notify({
          title: this.$t("GlobalsErrorTitle"),
          message: "some thing went wrong",
          type: "error",
        });
      }
    },
    addAd() {
      this.$refs["imageFiles"].click();
    },
    async saveAd(ad, i) {
      const valid = await this.$refs.adCard[i].$refs["AdForm"].validate();
      if (valid) {
        // eslint-disable-next-line no-unused-vars
        let adObject = {};
        try {
          const getPlacementValue = (ad) => {
            if (ad.placement === "provider") return ad.providerId;
            if (ad.placement === "section") return ad.sections;
            if (ad.placement === "home") return "home";
          };
          const getNavigationValue = (ad) => {
            if (ad.navigation === "provider") return ad.navigationProvideId;
            if (ad.navigation === "section") return ad.sectionsNavigation;
            if (ad.navigation === "deep_link") return ad.deep_link;
            if (ad.navigation === "none") return undefined;
          };
          const checkActionBtnLabel = (ad) => {
            if (ad.isCallToAction) return ad.btnName;
            else return undefined;
          };

          // const  adObject = {
          //   name: ad.name,
          //   uri: ad.mediaType == "image" ? ad.imageUrl : ad.videoUrl,
          //   areas: ad.areas,
          //   regions: ad.regions,
          //   sections: ad.sections,
          //   visible: ad.visible,
          //   type: ad.type,
          //   providerId: ad.providerId,
          //   destination: ad.destination ? ad.destination : undefined,
          //   start_date: ad.start_date,
          //   end_date: ad.end_date,
          //   ad_config: ad.ad_config,
          // };

          if (ad.type === "banner") {
            adObject = {
              startDate: ad.startDate,
              endDate: ad.endDate,
              name: ad.name,
              url: ad.mediaType == "image" ? ad.imageUrl : ad.videoUrl,
              // isActive: ad.visible,
              placementType: ad.placement,
              placementValue: getPlacementValue(ad),
              navigationType:
                ad.navigation === "none" ? undefined : ad.navigation,
              navigationValue: getNavigationValue(ad),
              actionButtonLabel: checkActionBtnLabel(ad),
              areas:
                ad.targeting === "areaTargeting" && ad.areas.length > 0
                  ? ad.areas
                  : undefined,
              type: ad.type,
            };
          } else {
            adObject = {
              name: ad.name,
              uri: ad.mediaType == "image" ? ad.imageUrl : ad.videoUrl,
              areas: ad.areas,
              regions: ad.regions,
              sections: ad.sections,
              visible: ad.visible,
              type: ad.type,
              providerId: ad.providerId,
              destination: ad.destination ? ad.destination : undefined,
              start_date: ad.start_date,
              end_date: ad.end_date,
              ad_config: ad.ad_config,
            };
          }
          const adResponse = await MarktingService.ads.createad(adObject);
          const createdAdId = adResponse.data.data.id;
          if (ad?.entitiesExcelFileList.length > 0) {
            await this.handleSheetImport(
              ad.entitiesExcelFileList[0],
              createdAdId
            );
          }

          const adIndex = this.adsList.findIndex(
            (a) => a[`${ad.mediaType}Url`] == ad[`${ad.mediaType}Url`]
          );
          this.selectedAds = this.selectedAds.filter(
            (a) => a[`${ad.mediaType}Url`] !== ad[`${ad.mediaType}Url`]
          );
          this.handleCheckedAdsChange(this.selectedAds);
          this.adsList[adIndex].isSaved = true;
          this.$notify({
            title: this.$t("globalsSuccessTitle"),
            message: this.$t("CreateAdPageCreateAdsMsg"),
            type: "success",
          });
        } catch (error) {
          this.$notify({
            title: this.$t("GlobalsErrorTitle"),
            message: error.response.data.message,
            type: "Error",
          });
        }
      }
    },
    async handleSheetImport(file, adId) {
      let toBeSentForm = new FormData();
      toBeSentForm.append("file", file.raw);
      try {
        const response = await MarktingService.ads.importEntitiesSheet(
          toBeSentForm,
          adId
        );
        if (response && response.data) {
          const responseData = response.data;
          let defaultType = "application/json; charset=utf-8";
          let responseContentType = response.headers["content-type"];
          if (defaultType === responseContentType) {
            let decodedString = String.fromCharCode.apply(
              null,
              new Uint8Array(responseData)
            );
            let parsedResponse = JSON.parse(decodedString);
            if (parsedResponse.message) {
              this.$message({
                message: parsedResponse.message,
                type: "success",
                duration: 2000,
              });
            } else {
              this.$message({
                message: "Success",
                type: "success",
                duration: 2000,
              });
            }
          } else {
            let blob = new Blob([responseData], {
              type: response.headers["content-type"],
            });
            this.$refs["sheet-download"].setAttribute(
              "href",
              window.URL.createObjectURL(blob)
            );
            this.$refs["sheet-download"].setAttribute(
              "download",
              `uploadEntitiesErrorSheet.xlsx`
            );
            this.$refs["sheet-download"].click();
            this.$message({
              message:
                "file uploaded successfully, please check the downloaded file",
              type: "success",
              duration: 4000,
            });
          }
        }
      } catch (error) {
        console.log("error :>> ", error);
        const errorMessage = this.$globalFunctions.errorMessageExtractor(error);
        this.$globalFunctions.popupMessageWrapper(errorMessage, "error", 4000);
      }
      this.sheetFileList = [];
      this.$emit("dataUpdated");
    },
    resetAd(index) {
      this.adsList[index].sections = [];
      this.adsList[index].regions = [];
      this.adsList[index].areas = [];
      this.adsList[index].ad_config = {};
    },
    async bulkEditAds(ad) {
      const confirmed = await this.$confirm(
        this.$t("CreateAdPageApplyToAllConfirmMsg"),
        this.$t("CreateAdPageApplyToAllConfirmTitle"),
        {
          confirmButtonText: this.$t("GlobalOkBtn"),
          cancelButtonText: this.$t("GlobalsCancelBtn"),
          type: "warning",
        }
      );
      if (confirmed) {
        this.selectedAds.forEach((selectedAd) => {
          if (!selectedAd?.isSaved) {
            const adIndex = this.adsList.findIndex(
              (adItem) =>
                selectedAd[`${selectedAd.mediaType}Url`] ==
                adItem[`${selectedAd.mediaType}Url`]
            );
            let bulkEditedKeys = Object.keys(ad);

            bulkEditedKeys.forEach((key) => {
              this.adsList[adIndex][key] = ad[key];
            });
          }
        });
      }
    },
    handleAdsCheckAllChange(val) {
      this.selectedAds = val ? this.adsList.filter((ad) => !ad?.isSaved) : [];
      this.isIndeterminate = false;
    },
    handleCheckedAdsChange(value) {
      let checkedCount = value.length;
      this.checkAll =
        checkedCount === this.adsList.filter((ad) => !ad?.isSaved).length;
      this.isIndeterminate =
        checkedCount > 0 &&
        checkedCount < this.adsList.filter((ad) => !ad?.isSaved).length;
    },
    hasChangedData(ad) {
      const { sectionType, regions, areas, ad_config, sections, providerId } =
        ad;
      const adConfigKeys = Object.keys(ad_config);

      if (
        sectionType !== "section" ||
        regions?.length ||
        areas?.length ||
        adConfigKeys?.length ||
        sections?.length ||
        providerId
      )
        return false;
      return true;
    },
  },
};
</script>
<template>
  <div>
    <h1>{{ $t("handleAdPageTitleLabel") }}</h1>
    <div class="ads-wrapper">
      <!-- ------------------------------------------- -->
      <!-- Apply To All Area -->
      <!-- ------------------------------------------- -->
      <el-collapse-transition>
        <apply-to-all-card
          v-show="showApplyToAllCard"
          @apply-to-all="bulkEditAds"
        ></apply-to-all-card>
      </el-collapse-transition>
      <el-checkbox
        v-if="this.adsList.length"
        :indeterminate="isIndeterminate"
        v-model="checkAll"
        @change="handleAdsCheckAllChange"
        >{{ $t("CreateAdPageCheckAllOption") }}</el-checkbox
      >
      <!-- ------------------------------------------- -->
      <!-- Ads Area -->
      <!-- ------------------------------------------- -->
      <el-checkbox-group v-model="selectedAds" @change="handleCheckedAdsChange">
        <div
          v-for="(ad, i) in adsList"
          :key="ad.key"
          class="u-bottom-margin--3x"
        >
          <el-checkbox v-if="!ad?.isSaved" :label="ad">
            {{ i + 1 }}</el-checkbox
          >
          <ad-details-card
            ref="adCard"
            :class="ad?.isSaved ? 'u-bg--green-lightest' : ''"
            :disabled="ad?.isSaved"
            @media-edit="mediaEdited"
            v-model="adsList[i]"
          >
            <div slot="actions">
              <el-button
                type="danger"
                v-if="!ad?.isSaved"
                :disabled="hasChangedData(ad)"
                size="mini"
                @click="resetAd(i)"
                >{{ $t("GlobalsDiscardBtn") }}</el-button
              >
              <el-button
                type="primary"
                :disabled="ad?.isSaved"
                size="mini"
                @click="saveAd(ad, i)"
                >{{ $t("handleAdPageButtonLabelSave") }}</el-button
              >
            </div>
          </ad-details-card>
        </div>
      </el-checkbox-group>
      <!-- ------------------------------------------- -->
      <!-- Add Ads Button -->
      <!-- ------------------------------------------- -->
      <a class="add-ad-btn" @click="addAd">
        <div class="icon-container">
          <input
            @change="handleMediaUpload"
            type="file"
            id="files"
            ref="imageFiles"
            name="adsImages"
            multiple="multiple"
            style="display: none"
          />
          <i class="el-icon-plus"></i>
        </div>
      </a>
      <a style="display: none" ref="sheet-download" target="_blank"></a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.el-checkbox-group {
  font-size: inherit;
}
.ads-wrapper {
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  gap: 2rem;
}
.add-ad-btn {
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 300px;
  height: 150px;
  border: 2px solid black;

  transition: 0.5s all;
  .icon-container {
    padding: 1rem;
    border: 1px solid black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:hover {
    opacity: 0.3;
  }
}
</style>
