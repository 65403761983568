<script>
import { mapGetters } from "vuex";
import dropdownCategoriesSelect from "../../../components/dropdownCategoriesSelect.vue";
import { providersService, regionsService } from "../../../services";
import extendedFunctions from "../../../utils/extendedFunctions";

import dropdownSelectAll from "../../../components/dropdownSelectAll.vue";

export default {
  name: "ApplyToAllCard",
  components: { dropdownCategoriesSelect, dropdownSelectAll },
  data() {
    return {
      adObj: {
        providerId: undefined,
        destination: undefined,
        regionsList: [],
        regions: [],
        areas: [],
        areasList: [],
        ad_config: {},
        mediaType: "video",
        sectionType: "section",
        type: "banner",
      },
      defaultData: {
        providerId: undefined,
        destination: undefined,
        regionsList: [],
        regions: [],
        areas: [],
        areasList: [],
        ad_config: {},
        mediaType: "video",
        sectionType: "section",
        type: "banner",
      },
      adFieldsShowable: [],
      adFieldsOptions: [
        { key: "type", label: "Ad type" },
        { key: "visible", label: "Visible" },
        { key: "sectionType", label: "Banner Ad Type" },
        { key: "regions", label: "regions" },
        { key: "areas", label: "areas" },
        { key: "sections", label: "sections" },
        { key: "mediaType", label: "mediaType" },
        { key: "providerId", label: "provider" },
        { key: "destination", label: "destination" },
        { key: "ad_config", label: "Pop up Ad configurations" },
      ],
      rules: {},
      showAreaFilters: false,
    };
  },
  computed: {
    ...mapGetters({ lookups: "getAllLookups" }),
    isBannerMode() {
      return this.adObj.type == "banner";
    },
    isPopupMode() {
      return this.adObj.type == "popup";
    },
  },
  methods: {
    applyToAll() {
      const mappedObject = {};
      this.adFieldsShowable.forEach((key) => {
        mappedObject[key] = this.adObj[key];
      });
      this.$emit("apply-to-all", mappedObject);
    },
    checkFieldShowable(field) {
      return this.adFieldsShowable.some((f) => f == field);
    },
    async getProviderPlaces() {
      try {
        const areas = await providersService.getProviderArea(
          this.adObj.providerId
        );
        const regions = await providersService.getProviderRegion(
          this.adObj.providerId
        );
        this.adObj.areas = [];
        this.adObj.regions = [];
        this.adObj.areasList = areas.data.data;
        this.adObj.regionsList = regions.data.data;
      } catch (err) {
        console.log(err);
      }
    },
    regionsChanged(regions) {
      this.showAreaFilters = regions.length && true;
      if (regions.length === 0) this.adObj.areasList = [];
    },
    async filterAreasByRegion() {
      try {
        const {
          data: {
            data: { areas },
          },
        } = await regionsService.getAreasLookup({
          filter: { regionId: this.adObj.regions },
        });

        this.adObj.areasList = areas.map((a) => ({ id: a.key, name: a.value }));
        this.adObj.areas = areas.map((a) => a.key);
        this.showAreaFilters = false;
      } catch (err) {
        this.$notify({
          title: this.$t("GlobalsErrorTitle"),
          message: extendedFunctions.errorMessageExtractor(err),
          type: "error",
        });
      }
    },
  },
  watch: {
    adFieldsShowable: {
      handler(currentState, oldSate) {
        oldSate.forEach((field) => {
          if (!currentState.some((f) => f == field))
            this.adObj[field] = this.defaultData[field];
        });
      },
    },
    adObj: {
      deep: true,
      handler(val) {
        this.$emit("input", val);
      },
    },
    "adObj.sectionType": {
      deep: true,
      handler(val) {
        if (val == "section") {
          this.adObj.providerId = undefined;
          this.adObj.destination = undefined;
        }
        this.adObj.areasList = [];
        this.adObj.regionsList = [];
        this.adObj.areas = [];
        this.adObj.regions = [];
        this.showAreaFilters = false;
      },
    },
    "adObj.type": {
      deep: true,
      handler(val) {
        // reset unwanted values when changing ad type
        if (val == "popup") {
          this.adObj.providerId = undefined;
          this.adObj.destination = undefined;
          this.adObj.areasList = [];
          this.adObj.regionsList = [];
          this.adObj.areas = [];
          this.adObj.regions = [];
          this.adObj.sectionType = "section";
          this.showAreaFilters = false;
        } else if (val == "banner") {
          this.adObj.ad_config = {};
          this.adObj.mediaType = "image";
        }
      },
    },

    "adObj.mediaType": {
      handler(val) {
        if (val == "video") {
          this.adObj.ad_config.time_to_end = undefined;
        }
      },
    },
  },
};
</script>

<template>
  <el-card shadow="hover">
    <el-collapse>
      <el-form
        :model="adObj"
        ref="AdForm"
        :rules="rules"
        :inline="false"
        size="normal"
      >
        <el-collapse-item>
          <!-- *********************************************************** -->
          <!-- Card-Header -->
          <!-- *********************************************************** -->
          <template slot="title">
            <div class="ad-card_header" @click.stop="1">
              <div class="ad-card_header_lead">
                <el-select v-model="adFieldsShowable" multiple filterable>
                  <el-option
                    v-for="item in adFieldsOptions"
                    :key="item.key"
                    :label="item.label"
                    :value="item.key"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="tail">
                <div class="u-bottom-margin--2x">
                  <el-button size="medium" type="primary" @click="applyToAll">{{
                    $t("HandleAdsPagesApplyToAllBtn")
                  }}</el-button>
                </div>
                <el-form-item>
                  <el-radio-group
                    :disabled="!checkFieldShowable('type')"
                    v-model="adObj.type"
                  >
                    <!-- <el-radio-button label="popup">{{
                      $t("handleAdPagePopupOptionLabel")
                    }}</el-radio-button> -->
                    <el-radio-button label="banner">{{
                      $t("handleAdPageBannerOptionLabel")
                    }}</el-radio-button>
                  </el-radio-group>
                </el-form-item>
                <el-form-item>
                  <el-radio-group
                    v-model="adObj.visible"
                    :disabled="!checkFieldShowable('visible')"
                  >
                    <el-radio-button :label="true">{{
                      $t("handleAdPageActiveOptionLabel")
                    }}</el-radio-button>
                    <el-radio-button :label="false">{{
                      $t("handleAdPageInActiveOptionLabel")
                    }}</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </div>
            </div>
          </template>
          <!-- *********************************************************** -->
          <!-- Card-body -->
          <!-- *********************************************************** -->
          <el-form-item>
            <el-radio-group
              :disabled="!checkFieldShowable('sectionType')"
              v-if="isBannerMode"
              v-model="adObj.sectionType"
            >
              <el-radio-button label="provider">{{
                $t("Provider")
              }}</el-radio-button>
              <el-radio-button label="section">{{
                $t("section")
              }}</el-radio-button>
            </el-radio-group>
            <el-radio-group
              :disabled="!checkFieldShowable('mediaType')"
              v-if="isPopupMode"
              v-model="adObj.mediaType"
            >
              <el-radio-button label="image">{{
                $t("CreateAdsPageTabImage")
              }}</el-radio-button>
              <el-radio-button label="video">{{
                $t("CreateAdsPage")
              }}</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <div class="ad-card_body">
            <!-- ********************* -->
            <!-- Body-col -->
            <!-- ********************* -->

            <div class="ad-card_body-col">
              <el-form-item
                :disabled="!checkFieldShowable('providerId')"
                v-if="adObj.sectionType == 'provider'"
                label="Provider"
                label-width="100px"
                size="normal"
              >
                <el-select
                  v-model="adObj.providerId"
                  @change="getProviderPlaces"
                  value-key=""
                  placeholder="Select Provider"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="item in lookups.distributers"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <template v-if="isPopupMode">
                <el-form-item
                  label="Time to open"
                  label-width="100px"
                  size="normal"
                >
                  <el-input
                    v-model="adObj.ad_config.time_to_open"
                    :disabled="!checkFieldShowable('ad_config')"
                    placeholder=""
                    size="normal"
                    clearable
                  ></el-input>
                </el-form-item>
                <el-form-item
                  v-if="adObj.mediaType == 'image'"
                  label="Time to end"
                  label-width="100px"
                  size="normal"
                >
                  <el-input
                    v-model="adObj.ad_config.time_to_end"
                    :disabled="!checkFieldShowable('ad_config')"
                    placeholder=""
                    size="normal"
                    clearable
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="Time to close"
                  label-width="100px"
                  size="normal"
                >
                  <el-input
                    v-model="adObj.ad_config.time_to_close"
                    placeholder=""
                    :disabled="!checkFieldShowable('ad_config')"
                    size="normal"
                    clearable
                  ></el-input>
                </el-form-item>
              </template>
              <el-form-item label="Section" label-width="100px" size="normal">
                <el-select
                  v-model="adObj.sections"
                  :disabled="!checkFieldShowable('sections')"
                  value-key="key"
                  placeholder="Select Sections"
                  clearable
                  filterable
                  multiple
                >
                  <el-option
                    v-for="item in lookups.sections"
                    :key="item.key"
                    :label="item.title"
                    :value="{ key: item.key, target: 'section' }"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <!-- ********************* -->
            <!-- Body-col -->
            <!-- ********************* -->
            <div class="ad-card_body-col">
              <el-form-item label="Regions" label-width="80px" size="normal">
                <template v-if="!adObj.regionsList.length">
                  <div class="u-display-flex u-align-items--center">
                    <dropdownCategoriesSelect
                      :disabled="!checkFieldShowable('regions')"
                      @change="regionsChanged"
                      v-model="adObj.regions"
                    />
                    <el-button
                      class="u-left-margin--2x"
                      v-if="showAreaFilters"
                      type="primary"
                      size="small"
                      @click="filterAreasByRegion"
                      >Filter Areas</el-button
                    >
                  </div>
                </template>
                <el-select
                  v-else
                  v-model="adObj.regions"
                  :disabled="!checkFieldShowable('regions')"
                  value-key=""
                  placeholder="Select Regions"
                  clearable
                  multiple=""
                  filterable
                >
                  <el-option
                    v-for="item in adObj.regionsList"
                    :key="item.id"
                    :label="item.region"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label-width="80px" label="Areas" size="normal">
                <dropdownSelectAll
                  v-model="adObj.areas"
                  :source="
                    adObj.areasList.length ? adObj.areasList : lookups.areas
                  "
                  :disabled="!checkFieldShowable('areas')"
                  placeholder="Select areas"
                  clearable
                  filterable
                >
                  <template v-slot:optionBody v-if="!adObj.areasList.length">
                    <el-option
                      v-for="item in lookups.areas"
                      :key="item.key"
                      :label="item.value"
                      :value="item.key"
                    >
                    </el-option>
                  </template>
                  <template v-slot:optionBody v-else>
                    <el-option
                      v-for="item in adObj.areasList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </template>
                </dropdownSelectAll>
              </el-form-item>
              <el-form-item
                label="Destination"
                v-if="adObj.sectionType == 'provider'"
                label-width="80px"
                size="normal"
              >
                <el-select
                  v-model="adObj.destination"
                  :disabled="!checkFieldShowable('destination')"
                  value-key=""
                  placeholder="Select Provider"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="item in lookups.distributers"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <el-table
            v-if="adObj.sections && adObj.sectionType == 'provider'"
            :data="adObj.sections"
          >
            <el-table-column label="Section" prop="key"> </el-table-column>
            <el-table-column label="Target">
              <template slot-scope="scope">
                <el-select v-model="scope.row.target" clearable filterable>
                  <el-option
                    v-for="item in targetOptions"
                    :key="item.key"
                    :label="item.label"
                    :value="item.key"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
          </el-table>
        </el-collapse-item>
      </el-form>
    </el-collapse>
  </el-card>
</template>

<style lang="scss" scoped>
// *********************************************************
// card header styles
// *********************************************************
.el-collapse {
  border: none;
}
::v-deep .el-collapse-item__header {
  padding: 2rem;
  font-size: inherit;
  border: none;
  height: auto !important;
  line-height: auto;
}
.ad-card_header {
  width: 90%;
  display: flex;
  align-items: center;
  img {
    max-width: 100%;
    height: auto;
  }
  .ad-card_header_lead {
    max-width: 33%;
    flex-basis: 33%;
  }
  .mid {
    flex-basis: 33%;
    margin: 0 1rem;
  }
  .tail {
    margin-left: auto;
    flex-basis: 33%;
    text-align: center;
  }
}
// *********************************************************
// card body styles
// *********************************************************
.ad-card_body {
  display: flex;
  padding: 2rem;
  gap: 2.5rem;
}
.ad-card_body-col {
  flex-basis: 50%;
  .el-select,
  .el-input {
    width: 100%;
  }
}
</style>
